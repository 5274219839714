import React, {useContext, useEffect, useMemo} from "react";
import {useParams,useLocation} from 'react-router-dom';
import {Button} from "react-bootstrap";
import {FileEarmarkArrowDown} from "react-bootstrap-icons";
import {OrderContext} from "../order/OrderContext";
import useAuth from "../auth/useAuth";
import queryString from 'query-string';
import InvoiceDownloadButton from "../Components/InvoiceDownloadButton";
import Alert from "react-bootstrap/Alert";

const OrderIssued = ({}) => {
    const {invoice} = useParams();
    const {reset} = useContext(OrderContext);
    const {accessToken} = useAuth();
    const location = useLocation();
    const {message} = useMemo(() => queryString.parse(location.search.substr(1)), [location]);

    useEffect(() => {
        reset();
    }, [reset]);

    return <div className='text-center pt-3'>
        {message ? <Alert variant={'warning'}>{message}</Alert> : null}
        <InvoiceDownloadButton
            id={invoice}
            className='mt-3'
            variant='light'
            size='lg'
        ><FileEarmarkArrowDown size='98'/><br/>Stáhnout</InvoiceDownloadButton>
    </div>;
}

export default OrderIssued;