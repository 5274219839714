import useAuth from "../auth/useAuth";
import {useContext, useMemo} from "react";
import queryString from 'query-string';
import {ModelContext} from "../model/ModelContext";

const useInvoiceLink = (id) => {
    const {accessToken} = useAuth();
    const {downloadURL} = useContext(ModelContext);
    return useMemo(() => downloadURL + "?" + queryString.stringify({invoiceId:id, access_token: accessToken}), [accessToken]);
}
export default useInvoiceLink;