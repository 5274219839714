import React, {useCallback, useEffect, useMemo, useState} from 'react';
import AuthContext from "./AuthContext";
import {useLocation} from "react-router-dom";
import queryString from 'query-string';
import useModel from "../model/useModel";

const useStorage = (storage) => {
    const [state, setState] = useState(() => storage.getData());
    const persistAuthResponse = useCallback((value) => {
        storage.persistAuthResponse(value);
        setState(storage.getData());
    }, [setState]);

    return [state, persistAuthResponse];
}

const AuthProvider = ({storage, setAuthorizationToken, ...props}) => {
    const location = useLocation();
    const [authState, persistAuthResponse] = useStorage(storage);
    const {process: authenticate, state: {result, isSuccess, isFetching}} = useModel((model) => model.user.authenticate);
    const {apiKey} = useMemo(() => queryString.parse(location.search.substr(1)), [location]);
    const [isAuthenticated, setIsAuthenticated] = useState(authState.accessToken);

    useEffect(() => {
        if (apiKey && apiKey !== authState.accessToken) {
            console.log('AUTHENTICATE apiKey', apiKey);
            authenticate({api_key: apiKey});
        }
    }, [authState.accessToken, apiKey]);

    useEffect(() => {
        if (authState.isExpired && authState.refreshToken) {
            console.log('NEED REFRESH', authState.refreshToken);
            authenticate({refresh_token: authState.refreshToken});
        }
    }, [authState]);

    //- vpripade ze se prihlaseni zdari, nastavi uzivatele jako prihlaseneho
    useEffect(() => {
        if (isSuccess) {
            persistAuthResponse(result);
            setIsAuthenticated(true);
            setAuthorizationToken(authState.accessToken);
        }
    }, [result, isSuccess, setAuthorizationToken, setIsAuthenticated, persistAuthResponse, authState.accessToken]);


    const model = useMemo(() => ({
        storage,
        username: storage.getUserName(),
        accessToken:storage.getAccessToken(),
        isAuthenticated: isAuthenticated,
        isFetching,
        // uspesny auth request a v url je apiKey
        needRedirect: isSuccess && apiKey,
        isExpired: authState.isExpired,
    }), [storage, isAuthenticated, isSuccess, isFetching, apiKey]);

    return <AuthContext.Provider value={model} {...props} />;
};

export default AuthProvider;